import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function Circle(props) {
  return (
    <div
      className="absolute rounded-full flex flex-col justify-center"
      style={{
        backgroundColor: `${props.bg}`,
        width: props.size || "150px",
        height: props.size || "150px",
        top: props.top || "0",
        left: props.left || "0",
      }}
    >
      <div className="relative w-full h-full">
        <div
          className="absolute"
          style={{
            top: props.textTop || "50%",
            left: props.textLeft || "10%",
            right: props.textRight || "10%",
            height: props.textHeight || "40%",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{ color: props.textColor }}
            className="text-center text-3xl font-black"
          >
            {props.text}
          </span>
        </div>
      </div>
    </div>
  );
}

function TemplateVennSimple(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "";
  let list = [];
  let noborder = false;
  let fullwidth = false;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "list") {
      list.push(myDecodeURIComponent(value));
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "invert") {
      invert = true;
    }
  });

  list = list.filter((x) => x);
  var text1 = list.length > 0 ? list[0] : "";
  var text2 = list.length > 1 ? list[1] : "";
  var text3 = list.length > 2 ? list[2] : "";

  var padding = 40;
  var circle_w = (w - padding * 2) / 3;

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        height: `${h}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className={`w-full flex-1 ${
          invert ? "bg-blue-800" : "bg-gray-100"
        } relative flex flex-col justify-center`}
        style={{ padding: `${padding}px` }}
      >
        <div className="relative flex-grow flex-shrink-0">
          <Circle
            bg="rgba(3, 4, 94,1)"
            textColor="white"
            textTop={"20%"}
            top={padding - padding/2}
            left={w / 2 - circle_w / 2 - padding}
            size={circle_w}
            text={text1}
          />
          <Circle
            bg="rgba(0, 119, 182,0.9)"
            textTop={"40%"}
            textLeft={"5%"}
            textRight={"20%"}
            textHeight={"30%"}
            textColor="white"
            top={circle_w / 2 + 2*padding - padding/2}
            left={w / 2 - circle_w}
            size={circle_w}
            text={text2}
          />
          <Circle
            bg="rgba(	69, 123, 157,0.8)"
            textTop={"40%"}
            textRight={"5%"}
            textLeft={"20%"}
            textHeight={"30%"}
            textColor="white"
            top={circle_w / 2 + 2*padding - padding/2}
            left={w / 2 - 2 * padding}
            size={circle_w}
            text={text3}
          />
          <div style={{
            top: "51%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }} className="absolute w-full h-full flex justify-center items-center">
            <span className="text-3xl font-black text-white"><i className="ri-check-line"></i></span>
          </div>
          <h1
            className={`text-3xl font-black ${
              invert ? "text-white" : "text-blue-800"
            } leading-none`}
            style={{
              position: "absolute",
              top: "95%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {heading}
          </h1>
        </div>

        <img
          className="absolute bottom-2 right-5"
          style={{
            height: "50px",
            objectFit: "contain",
            width: "120px",
            zIndex: 10,
            objectPosition: "center",
            filter: invert ? "brightness(0) invert(1)" : "none",
            opacity: 0.85,
          }}
          src={logo}
        />
      </div>
    </div>
  );
}

export default TemplateVennSimple;
