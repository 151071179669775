import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateSliderSimple(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "";
  let low = "";
  let lowValue = 0;
  let high = "";
  let highValue = 0;
  let point = "";
  let pointValue = 0;
  let unit = "";
  let noborder = false;
  let fullwidth = false;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "unit") {
      unit = myDecodeURIComponent(value);
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "invert") {
      invert = true;
    } else if (key === "low") {
      low = myDecodeURIComponent(value);
    } else if (key === "lowValue") {
      lowValue = Number(myDecodeURIComponent(value));
    } else if (key === "high") {
      high = myDecodeURIComponent(value);
    } else if (key === "highValue") {
      highValue = Number(myDecodeURIComponent(value));
    } else if (key === "point") {
      point = myDecodeURIComponent(value);
    } else if (key === "pointValue") {
      pointValue = Number(myDecodeURIComponent(value));
    }
  });

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className={`w-full h-full min-h-96  flex-1 ${
          invert ? "bg-blue-800" : "bg-gray-100"
        } px-10 pt-10 pb-12 relative flex flex-col`}
      >
        <h1
          className={`text-2xl mb-10 font-black ${
            invert ? "text-white" : "text-blue-800"
          } leading-none`}
        >
          {heading}
        </h1>

        <div className="flex flex-grow justify-center flex-col w-full px-10 mb-10">
          <div className="relative flex flex-row justify-between">
            <div
              className="flex flex-col justify-end text-center pb-2 absolute"
              style={{
                width: "140px",
                bottom: "0",
                height: "80px",
                left: `calc(${
                  ((pointValue - lowValue) / (highValue - lowValue)) * 100
                }% - 70px)`,
              }}
            >
              <div>
                <span className="text-md font-black">{`${pointValue} ${unit}`}</span>
              </div>
              <div className="-mt-1.5">
                <span className="text-sm font-black  text-blue-800">{`${point}`}</span>
              </div>
            </div>
          </div>
          <div className="relative h-2 bg-blue-300 flex flex-row">
            <div
              className="absolute h-full bg-blue-600 rounded-full"
              style={{
                left: `calc(${
                  ((pointValue - lowValue) / (highValue - lowValue)) * 100
                }% - 9px)`,
                width: "18px",
                height: "18px",
                top: "-5.5px",
              }}
            ></div>
          </div>
          <div className="relative">
            <div
              className="absolute text-center"
              style={{ left: "-70px", top: "0", width: "140px", height: "80px" }}
            >
              <div>
                <span className="text-md font-black">{`${lowValue} ${unit}`}</span>
              </div>
              <div className="-mt-1.5">
                <span className="text-sm font-black text-blue-800">{`${low}`}</span>
              </div>
            </div>
            <div
              className="absolute text-center"
              style={{ right: "-70px", top: "0", width: "140px", height: "80px" }}
            >
              <div>
                <span className="text-md font-black">{`${highValue} ${unit}`}</span>
              </div>
              <div className="-mt-1.5">
                <span className="text-sm font-black  text-blue-800">{`${high}`}</span>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute bottom-2 right-5"
          style={{
            height: "50px",
            objectFit: "contain",
            width: "120px",
            zIndex: 10,
            objectPosition: "center",
            filter: invert ? "brightness(0) invert(1)" : "none",
            opacity: 0.85,
          }}
          src={logo}
        />
      </div>
    </div>
  );
}

export default TemplateSliderSimple;
