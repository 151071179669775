import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateBarsSimple(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "";
  let list = [];
  let values = [];
  let unit = "";
  let noborder = false;
  let fullwidth = false;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "list") {
      list.push(myDecodeURIComponent(value));
    } else if (key === "values") {
      values.push(Number(myDecodeURIComponent(value)));
    } else if (key === "unit") {
      unit = myDecodeURIComponent(value);
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "invert") {
      invert = true;
    }
  });

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className={`w-full h-full min-h-96  flex-1 ${
          invert ? "bg-blue-800" : "bg-gray-100"
        } px-10 pt-10 pb-12 relative flex flex-col`}
      >
        <h1
          className={`text-2xl mb-10 font-black ${
            invert ? "text-white" : "text-blue-800"
          } leading-none`}
        >
          {heading}
        </h1>

        <div className={`flex flex-row ${list.length < 4 ? "space-x-8": "space-x-2"}  mt-6 flex-grow border-b-1 border-gray-300`}>
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col flex-grow flex-shrink-0"
              >
                <div className="flex flex-col-reverse relative flex-grow h-full">
                  <div
                    className="border-l-2 border-r-2 relative border-t-2 border-b-1 border-blue-500 bg-blue-200"
                    style={{
                      height: `${(values[index] / Math.max(...values)) * 100}%`,
                    }}
                  >
                    <div className="absolute -top-6 left-0 right-0 text-center">
                      <span className="text-xs font-black text-gray-700">{`${values[index]} ${unit}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={`flex flex-row ${list.length < 4 ? "space-x-8": "space-x-2"} h-14 text-center`}>
          {list.map((item, index) => {
            return (
              <div style={{ width: `${(1 / list.length) * 100}%` }}>
                <span className="text-sm font-black text-center text-gray-700">{item}</span>
              </div>
            );
          })}
        </div>

        <img
          className="absolute bottom-2 right-5"
          style={{
            height: "50px",
            objectFit: "contain",
            width: "120px",
            zIndex: 10,
            objectPosition: "center",
            filter: invert ? "brightness(0) invert(1)" : "none",
            opacity: 0.85,
          }}
          src={logo}
        />
      </div>
    </div>
  );
}

export default TemplateBarsSimple;
